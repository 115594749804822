var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        [
          _c("b-card-title", { staticClass: "mb-sm-0 mb-1" }, [
            _vm._v(" Statistics ")
          ])
        ],
        1
      ),
      _c(
        "b-card-body",
        [
          _c("vue-apex-charts", {
            attrs: {
              type: "radialBar",
              height: "400",
              options: _vm.apexChatData.radialBarChart.chartOptions,
              series: _vm.apexChatData.radialBarChart.series
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }